<template>
  <div class="agent">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>代理账号</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-bar">
      <div class="left">
        <el-form :inline="true" :model="query" class="search-form-inline">
          <el-form-item>
            <el-input v-model="query.title" placeholder="请输入手机号或手机号" clearable @clear="getDataList"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getDataList">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="right">
        <el-button type="primary" @click="addVisible = true">添加新账号</el-button>
      </div>
    </div>
    <div class="page-content">
      <el-table :data="dataList" border style="width: 100%" max-height="500">
        <el-table-column prop="id" label="#" width="80"></el-table-column>
        <el-table-column prop="m_id" label="混合ID" width="120"></el-table-column>
        <el-table-column prop="phone" label="手机号" width="180"></el-table-column>
        <el-table-column prop="r_title" label="所在角色组">
          <template slot-scope="scope">
            <el-tag type="warning" v-if="scope.row.role === 0">主管理员</el-tag>
            <el-tag v-else>{{scope.row.r_title}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="nick" label="昵称"></el-table-column>
        <el-table-column prop="company" label="公司名称"></el-table-column>
        <el-table-column fixed="right" label="操作" width="220">
          <template slot-scope="scope">
            <el-button size="small" @click="viewItem(scope.row.m_id)">查看</el-button>
            <el-button size="small" @click="editItem(scope.row.m_id)">编辑</el-button>
            <el-button type="danger" size="small" @click="delItem(scope.row.m_id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加账号弹窗 -->
    <el-dialog title="添加账号" :visible.sync="addVisible" width="30%" top="4vh" :before-close="addClose">
      <el-form :model="addForm" :rules="addRules" ref="addRef" class="add-form" label-position="top">
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="addForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="addForm.password" show-password></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nick">
          <el-input v-model="addForm.nick"></el-input>
        </el-form-item>
        <el-form-item label="头像" prop="avatar">
          <upload :url="addForm.avatar" :filed="'avatar'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="公司名称" prop="company">
          <el-input v-model="addForm.company"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="mark">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入内容" v-model="addForm.mark" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="所在角色组:" prop="roleId">
          <el-select v-model="addForm.roleId" placeholder="请选择角色组">
            <el-option v-for="item in roleList" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="到期时间" prop="over_time">
          <el-date-picker v-model="addForm.over_time" type="datetime" placeholder="选择日期时间" value-format="timestamp" @change="addOverTime"></el-date-picker>
          <el-checkbox v-model="addForm.forever" style="margin-left: 20px;" @change="addForever">永久有效</el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addSubmit('addRef')">新增账号</el-button>
          <el-button @click="cancellAdd">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 修改账号弹窗 -->
    <el-dialog title="修改账号" :visible.sync="editVisible" width="50%" top="4vh" :before-close="editClose">
      <el-form :model="editForm" :rules="editRules" ref="editRef" class="edit-form" label-position="top">
        <el-form-item label="所在角色组:" prop="role">
          <el-select v-model="editForm.roleId" placeholder="请选择角色组">
            <el-option v-for="item in roleList" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公司名称:" prop="company">
          <el-input v-model="editForm.company"></el-input>
        </el-form-item>
        <el-form-item label="备注:" prop="mark">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入内容" v-model="editForm.mark" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="editSubmit('editRef')">修改账号</el-button>
          <el-button @click="cancellEdit">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 查看账号弹窗 -->
    <el-dialog title="查看账号" :visible.sync="viewVisible" width="50%">
      <el-descriptions class="margin-top" title="账户信息" :column="2" size="medium" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            混合ID
          </template>
          {{ viewForm.m_id }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-picture-outline-round"></i>
            头像
          </template>
          <el-avatar :src="viewForm.avatar"></el-avatar>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            昵称
          </template>
          {{ viewForm.nick }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-phone"></i>
            手机号
          </template>
          <el-tag size="small">{{ viewForm.phone }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-position"></i>
            上次登录IP
          </template>
          {{ viewForm.last_ip }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            上次登录地区
          </template>
          {{ viewForm.last_area }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-time"></i>
            上次登录时间
          </template>
          {{ viewForm.last_time }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-position"></i>
            本次登录IP
          </template>
          {{ viewForm.ip }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            本次登录地区
          </template>
          {{ viewForm.area }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-time"></i>
            本次登录时间
          </template>
          {{ viewForm.time }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-time"></i>
            账号创建时间
          </template>
          {{ viewForm.creat_time }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '../common/Upload'
export default {
  name: 'Agent',
  components: {
    Upload
  },
  data () {
    const validatorPhone = function (rule, value, callback) {
      if (value === '') {
        callback(new Error('手机号不能为空'))
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    return {
      query: {
        title: ''
      },
      dataList: [],
      roleList: [],
      addForm: {
        phone: '',
        password: '',
        nick: '',
        avatar: '',
        company: '',
        mark: '',
        roleId: null,
        over_time: '',
        forever: true
      },
      addRules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatorPhone, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' }
        ],
        company: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ]
      },
      // 添加弹窗
      addVisible: false,
      viewVisible: false,
      viewForm: {
        m_id: '',
        avatar: '',
        nick: '',
        phone: '',
        last_ip: '',
        last_area: '',
        last_time: '',
        ip: '',
        area: '',
        time: '',
        creat_time: ''
      },
      editForm: {
        m_id: '',
        roleId: null,
        company: '',
        mark: ''
      },
      editRules: {
        company: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ]
      },
      // 添加弹窗
      editVisible: false
    }
  },
  created () {
    this.getDataList()
    this.getAllRoles()
  },
  methods: {
    // 获取已添加代理账号列表
    async getDataList () {
      const { data: res } = await this.$http.get('agent-list', { params: this.query })
      if (res.status === 200) {
        this.dataList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 获取所有角色
    async getAllRoles () {
      const { data: res } = await this.$http.get('role-list')
      if (res.status === 200) {
        this.roleList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 关闭弹窗
    addClose (done) {
      this.$refs.addRef.resetFields()
      done()
    },
    // 添加账号
    addSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('agent/0', this.addForm)
          if (res.status === 200) {
            await this.getDataList()
            this.$refs.addRef.resetFields()
            this.addVisible = false
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 取消添加账号
    cancellAdd () {
      this.$refs.addRef.resetFields()
      this.addVisible = false
    },
    // 点击到期时间选择框
    addOverTime (val) {
      if (val !== '') {
        this.addForm.forever = false
      }
    },
    // 添加账号到期时间勾选永久
    addForever (newVal) {
      if (newVal) {
        this.addForm.over_time = ''
      } else {
        this.addForm.over_time = Date.now()
      }
    },
    // 关闭弹窗
    editClose (done) {
      this.$refs.editRef.resetFields()
      done()
    },
    // 查看账号信息
    async viewItem (id) {
      const { data: res } = await this.$http.get('agent/' + id)
      if (res.status === 200) {
        this.viewForm.m_id = res.data.m_id
        this.viewForm.avatar = res.data.avatar
        this.viewForm.nick = res.data.nick
        this.viewForm.phone = res.data.phone
        this.viewForm.last_ip = res.data.last_ip
        this.viewForm.last_area = res.data.last_area
        this.viewForm.last_time = res.data.last_time ? this.$moment(res.data.last_time * 1000).format('YYYY-MM-DD HH:mm:ss') : '无'
        this.viewForm.ip = res.data.ip
        this.viewForm.area = res.data.area
        this.viewForm.time = res.data.time ? this.$moment(res.data.time * 1000).format('YYYY-MM-DD HH:mm:ss') : '无'
        this.viewForm.creat_time = res.data.creat_time ? this.$moment(res.data.creat_time * 1000).format('YYYY-MM-DD HH:mm:ss') : '无'
        this.viewVisible = true
      } else {
        this.$message.error(res.msg)
      }
    },
    // 编辑账号
    async editItem (mId) {
      const { data: res } = await this.$http.get('agent/' + mId)
      if (res.status === 200) {
        this.editForm.m_id = res.data.m_id
        this.editForm.roleId = res.data.roleId
        this.editForm.company = res.data.company
        this.editForm.mark = res.data.mark
        this.editVisible = true
      } else {
        this.$message.error(res.msg)
      }
    },
    // 删除账号
    async delItem (id) {
      const { data: res } = await this.$http.delete('agent/' + id)
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getDataList()
        await this.getParentData()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 修改账号
    editSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.put('agent/' + this.editForm.m_id, this.editForm)
          if (res.status === 200) {
            await this.getDataList()
            this.$refs.editRef.resetFields()
            this.editVisible = false
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 取消添加账号
    cancellEdit () {
      this.$refs.editRef.resetFields()
      this.editVisible = false
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.addForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.addForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.addForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.addForm[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>
.page-content{
  margin-top: 20px;
}
.search-bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-form-inline .el-form-item{
  margin-bottom: 0;
}
.add-form{
  padding: 0 40px;
  text-align: left;
}
.edit-form{
  padding: 0 40px;
  text-align: left;
}
/deep/.el-form--label-top .el-form-item__label{
  padding: 0;
}
.el-form-item{
  margin-bottom: 8px;
}
.el-descriptions{
  padding: 0 40px;
}
</style>
